import Head from 'next/head';
import type { PageCustomProps } from '@pages/_app';
import Listing from '@customers/reservations/listing';

const ReservationsPage: PageCustomProps = () => {
  return (
    <>
      <Head>
        <title>Reservations</title>
      </Head>
      <>
        <Listing />
      </>
    </>
  );
};

ReservationsPage.pageTitle = 'Reservations';

export default ReservationsPage;
